[
  {
    "name": "Get started",
    "path": "assets/markdown-files/get-started/overview.md",
    "children": [
      {
        "name": "Figma",
        "path": "assets/markdown-files/get-started/figma.md"
      },
      {
        "name": "Web ",
        "path": "assets/markdown-files/get-started/web.md"
      },
      {
        "name": "iOS ",
        "path": "assets/markdown-files/get-started/ios.md"
      },
      {
        "name": "Android ",
        "path": "assets/markdown-files/get-started/android.md"
      }
    ]
  },
  {
    "name": "Design Tokens",
    "path": "assets/markdown-files/tokens/overview.md",
    "children": [
      {
        "name": "Primitive Colors",
        "path": "assets/markdown-files/tokens/primitive/colors.md"
      },
      {
        "name": "Semantic Colors",
        "path": "assets/markdown-files/tokens/semantic/colors/overview.md",
        "children": [
          {
            "name": "Background Colors",
            "path": "assets/markdown-files/tokens/semantic/colors/background.md"
          },
          {
            "name": "On Background Colors",
            "path": "assets/markdown-files/tokens/semantic/colors/on-background.md"
          },
          {
            "name": "Foreground Colors",
            "path": "assets/markdown-files/tokens/semantic/colors/foreground.md"
          },
          {
            "name": "Border Colors",
            "path": "assets/markdown-files/tokens/semantic/colors/border.md"
          },
          {
            "name": "Focus Colors",
            "path": "assets/markdown-files/tokens/semantic/colors/focus.md"
          },
          {
            "name": "Link Colors",
            "path": "assets/markdown-files/tokens/semantic/colors/link.md"
          }
        ]
      },
      {
        "name": "Migration in iOS",
        "path": "assets/markdown-files/tokens/migration/ios/overview.md"
      },
      {
        "name": "Migration in Android",
        "path": "assets/markdown-files/tokens/migration/android/overview.md"
      },
      {
        "name": "Migration in Web",
        "path": "assets/markdown-files/tokens/migration/web/overview.md"
      },
      {
        "name": "Migration in Figma",
        "path": "assets/markdown-files/tokens/migration/figma/overview.md",
        "children": [
          {
            "name": "Step 1 Theme Migration",
            "path": "assets/markdown-files/tokens/migration/figma/theme.md"
          },
          {
            "name": "Step 2 Library Migration",
            "path": "assets/markdown-files/tokens/migration/figma/library.md"
          },
          {
            "name": "Step 3 Journey Migration",
            "path": "assets/markdown-files/tokens/migration/figma/journey.md"
          }
        ]
      },
      {
        "name": "Value & Cost Of Migration",
        "path": "assets/markdown-files/tokens/migration/value-&-cost/overview.md"
      },
      {
        "name": "Deprecated tokens",
        "path": "assets/markdown-files/tokens/deprecated/design-tokens.md"
      },
      {
        "name": "Deprecated token API",
        "path": "assets/markdown-files/tokens/deprecated/design-token-api.md",
        "hide": true
      }
    ]
  },
  {
    "name": "Theme",
    "path": "assets/markdown-files/theme/overview.md",
    "children": [
      {
        "name": "Create a theme",
        "path": "assets/markdown-files/theme/create-a-theme.md"
      },
      {
        "name": "Apply a theme on Android",
        "path": "assets/markdown-files/theme/apply-theme-android.md"
      },
      {
        "name": "Apply a theme on iOS",
        "path": "assets/markdown-files/theme/apply-theme-ios.md"
      },
      {
        "name": "Apply a theme on Web",
        "path": "assets/markdown-files/theme/apply-theme-web.md"
      },
      {
        "name": "Multi-theming on Android",
        "path": "assets/markdown-files/theme/multi-theme-android.md"
      },
      {
        "name": "Multi-theming on iOS",
        "path": "assets/markdown-files/theme/multi-theme-ios.md"
      },
      {
        "name": "Multi-theming on Web",
        "path": "assets/markdown-files/theme/multi-theme-web.md"
      },
      {
        "name": "Before 2024-09",
        "path": "assets/markdown-files/theme/before-2024-09/overview.md",
        "children": [
          {
            "name": "Create a theme",
            "path": "assets/markdown-files/theme/before-2024-09/create-a-theme.md"
          },
          {
            "name": "Apply a theme on Android",
            "path": "assets/markdown-files/theme/before-2024-09/apply-theme-android.md"
          },
          {
            "name": "Apply a theme on iOS",
            "path": "assets/markdown-files/theme/before-2024-09/apply-theme-ios.md"
          },
          {
            "name": "Apply a theme on Web",
            "path": "assets/markdown-files/theme/before-2024-09/apply-theme-web.md"
          },
          {
            "name": "Multi-theming on Android",
            "path": "assets/markdown-files/theme/before-2024-09/multi-theme-android.md"
          },
          {
            "name": "Multi-theming on iOS",
            "path": "assets/markdown-files/theme/before-2024-09/multi-theme-ios.md"
          },
          {
            "name": "Multi-theming on Web",
            "path": "assets/markdown-files/theme/before-2024-09/multi-theme-web.md"
          }
        ]
      }
    ]
  },
  {
    "name": "Accessibility",
    "children": [
      {
        "name": "Overview",
        "path": "assets/markdown-files/accessibility/overview.md"
      },
      {
        "name": "Designer checklist",
        "path": "assets/markdown-files/accessibility/designer-checklist.md"
      },
      {
        "name": "Developer checklist",
        "path": "assets/markdown-files/accessibility/for-developers.md"
      }
    ]
  },
  {
    "name": "Guides",
    "path": "assets/markdown-files/guides/introduction.md",
    "children": [
      {
        "name": "Web Developers",
        "children": [
          {
            "name": "Custom Icons",
            "path": "assets/markdown-files/guides/web-developers/custom-icons.md"
          }
        ]
      }
    ]
  },
  {
    "name": "Web Components",
    "path": "assets/markdown-files/web-components/overview.md",
    "children": [
      {
        "name": "Account number",
        "storybook": "account-number"
      },
      {
        "name": "Account selector",
        "storybook": "account-selector"
      },
      {
        "name": "Alert",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/alert.md",
        "storybook": "alert"
      },
      {
        "name": "Amount",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/amount.md",
        "storybook": "amount"
      },
      {
        "name": "Avatar",
        "storybook": "avatar"
      },
      {
        "name": "Badges",
        "path": "assets/markdown-files/design-guidelines/badges.md",
        "children": [
          {
            "name": "Badge",
            "storybook": "badge"
          },
          {
            "name": "Badge Counter",
            "storybook": "badge-counter"
          }
        ]
      },
      {
        "name": "Button",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/button.md",
        "storybook": "button"
      },
      {
        "name": "Card vendor",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/card-vendor.md",
        "storybook": "card-vendor"
      },
      {
        "name": "Chip",
        "storybook": "chip"
      },
      {
        "name": "Collapsibles",
        "path": "assets/markdown-files/design-guidelines/collapsibles.md",
        "children": [
          {
            "name": "Collapsible",
            "storybook": "collapsible"
          },
          {
            "name": "Collapsible Card",
            "storybook": "collapsible-card"
          },
          {
            "name": "Collapsible Accordion",
            "storybook": "collapsible-accordion"
          }
        ]
      },
      {
        "name": "Dropdowns",
        "path": "assets/markdown-files/design-guidelines/dropdowns.md",
        "children": [
          {
            "name": "Dropdown Menu",
            "storybook": "dropdown-menu"
          },
          {
            "name": "Dropdown Single Select",
            "storybook": "dropdown-single-select"
          },
          {
            "name": "Dropdown Multi Select",
            "storybook": "dropdown-multi-select"
          },
          {
            "name": "Dropdown Panel",
            "storybook": "dropdown-panel"
          }
        ]
      },
      {
        "name": "Ellipsis",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/ellipsis.md",
        "storybook": "ellipsis"
      },
      {
        "name": "File attachment",
        "storybook": "file-attachment"
      },
      {
        "name": "Forms",
        "path": "assets/markdown-files/design-guidelines/forms.md",
        "children": [
          {
            "name": "Amount Input",
            "storybook": "amount-input"
          },
          {
            "name": "Input Checkbox",
            "storybook": "input-checkbox"
          },
          {
            "name": "Checkbox Group",
            "storybook": "checkbox-group"
          },
          {
            "name": "Currency Input",
            "storybook": "currency-input"
          },
          {
            "name": "Input Datepicker",
            "storybook": "input-datepicker"
          },
          {
            "name": "Input Email",
            "storybook": "input-email"
          },
          {
            "name": "Fieldset",
            "storybook": "fieldset"
          },
          {
            "name": "Input Inline Edit",
            "storybook": "input-inline-edit"
          },
          {
            "name": "List Multiple Select",
            "web": true
          },
          {
            "name": "Input Phone",
            "storybook": "input-phone"
          },
          {
            "name": "Input Password",
            "storybook": "input-password"
          },
          {
            "name": "Input Radio Group",
            "storybook": "input-radio-group"
          },
          {
            "name": "Input Range",
            "storybook": "input-range"
          },
          {
            "name": "Switch",
            "storybook": "switch"
          },
          {
            "name": "Select List",
            "storybook": "select-list"
          },
          {
            "name": "Input Timepicker",
            "storybook": "input-timepicker"
          },
          {
            "name": "Input Text",
            "storybook": "input-text"
          },
          {
            "name": "Textarea",
            "storybook": "textarea"
          },
          {
            "name": "Input Validation Message",
            "storybook": "input-validation-message"
          },
          {
            "name": "Input File",
            "storybook": "input-file"
          },
          {
            "name": "Input Number",
            "storybook": "input-number"
          }
        ]
      },
      {
        "name": "Header",
        "web": true
      },
      {
        "name": "Heading",
        "web": true
      },
      {
        "name": "Icon",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/icon.md",
        "storybook": "icon"
      },
      {
        "name": "Illustration",
        "storybook": "illustration"
      },
      {
        "name": "Icon Set",
        "path": "assets/markdown-files/web-components/icon-set.md",
        "hide": true
      },
      {
        "name": "Infinite scroll",
        "storybook": "infinite-scroll"
      },
      {
        "name": "Item log",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/item-log.md",
        "storybook": "item-log"
      },
      {
        "name": "Layouts and navigation",
        "children": [
          {
            "name": "Horizontal Layout",
            "designGuidelinesPath": "assets/markdown-files/design-guidelines/horizontal-layout.md",
            "storybook": "horizontal-layout"
          },
          {
            "name": "Vertical Layout",
            "designGuidelinesPath": "assets/markdown-files/design-guidelines/vertical-layout.md",
            "storybook": "vertical-layout"
          }
        ]
      },
      {
        "name": "Keyboard Click Directive",
        "storybook": "keyboard-click"
      },
      {
        "name": "Loaders",
        "path": "assets/markdown-files/design-guidelines/loaders.md",
        "children": [
          {
            "name": "Loading Indicator",
            "storybook": "loading-indicator"
          },
          {
            "name": "Load Button",
            "storybook": "load-button"
          }
        ]
      },
      {
        "name": "Locale selector",
        "storybook": "locale-selector"
      },
      {
        "name": "Logo",
        "storybook": "logo"
      },
      {
        "name": "Modal",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/modal.md",
        "storybook": "modal"
      },
      {
        "name": "Mode Header",
        "storybook": "mode-header"
      },
      {
        "name": "Notification",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/notification.md",
        "storybook": "notification"
      },
      {
        "name": "Pagination",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/pagination.md",
        "storybook": "pagination"
      },
      {
        "name": "Page header",
        "storybook": "page-header",
        "storybookParentPath": "universal"
      },
      {
        "name": "Payment card",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/payment-card.md",
        "storybook": "payment-card"
      },
      {
        "name": "Period selector",
        "storybook": "period-selector"
      },
      {
        "name": "Product items",
        "path": "assets/markdown-files/design-guidelines/product-items.md",
        "children": [
          {
            "name": "Product Item Basic Account",
            "storybook": "product-item-basic-account"
          },
          {
            "name": "Product Item Savings Account",
            "storybook": "product-item-savings-account"
          },
          {
            "name": "Product Item Current Account",
            "storybook": "product-item-current-account"
          },
          {
            "name": "Product Item Investment Account",
            "storybook": "product-item-investment-account"
          },
          {
            "name": "Product Item Credit Card",
            "storybook": "product-item-credit-card"
          },
          {
            "name": "Product Item Debit Card",
            "storybook": "product-item-debit-card"
          },
          {
            "name": "Product Item Loan",
            "storybook": "product-item-loan"
          },
          {
            "name": "Product Item Term Deposit",
            "storybook": "product-item-term-deposit"
          }
        ]
      },
      {
        "name": "Product selector",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/product-selector.md",
        "storybook": "product-selector"
      },
      {
        "name": "Progress tracker",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/progress-tracker.md",
        "storybook": "progress-tracker"
      },
      {
        "name": "Progressbar",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/progressbar.md",
        "storybook": "progressbar"
      },
      {
        "name": "Rich text editor",
        "storybook": "rich-text-editor"
      },
      {
        "name": "Search box",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/search-box.md",
        "storybook": "search-box"
      },
      {
        "name": "States",
        "path": "assets/markdown-files/design-guidelines/states.md",
        "children": [
          {
            "name": "Action Status",
            "storybook": "action-status"
          },
          {
            "name": "Empty State",
            "storybook": "empty-state"
          },
          {
            "name": "Common Error State",
            "storybook": "common-error-state"
          }
        ]
      },
      {
        "name": "Stepper",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/stepper.md",
        "storybook": "stepper"
      },
      {
        "name": "Tab",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/tab.md",
        "storybook": "tab"
      },
      {
        "name": "Table",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/table.md",
        "storybook": "table"
      },
      {
        "name": "Tooltip",
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/tooltip.md",
        "storybook": "tooltip"
      },
      {
        "name": "Tracker",
        "storybook": "tracker"
      },
      {
        "name": "Value diff",
        "storybook": "value-diff"
      }
    ]
  },
  {
    "name": "Web Formatters",
    "path": "assets/markdown-files/web-formatters/overview-formatters.md",
    "children": [
      {
        "name": "Account Number Pipe",
        "storybook": "account-number-pipe",
        "storybookParentPath": "formatters"
      },
      {
        "name": "Date Pipe",
        "designGuidelinesPath": "assets/markdown-files/web-formatters/date.md",
        "storybook": "date-pipe",
        "storybookParentPath": "formatters"
      },
      {
        "name": "Iban Pipe",
        "designGuidelinesPath": "assets/markdown-files/web-formatters/iban-format.md",
        "storybook": "iban-pipe",
        "storybookParentPath": "formatters"
      },
      {
        "name": "Payment Card Number Pipe",
        "designGuidelinesPath": "assets/markdown-files/web-formatters/card-number.md",
        "storybook": "payment-card-number-pipe",
        "storybookParentPath": "formatters"
      },
      {
        "name": "Phone Number Pipe",
        "designGuidelinesPath": "assets/markdown-files/web-formatters/phone-numbers.md",
        "storybook": "phone-number-pipe",
        "storybookParentPath": "formatters"
      }
    ]
  },
  {
    "name": "Mobile Components",
    "path": "assets/markdown-files/mobile-components/overview.md",
    "children": [
      {
        "name": "Alert",
        "android": true,
        "ios": true,
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/alert.md"
      },
      {
        "name": "Amount",
        "android": true,
        "ios": true
      },
      {
        "name": "Avatar",
        "android": true,
        "ios": true
      },
      {
        "name": "Badge",
        "android": true,
        "ios": true
      },
      {
        "name": "Badge counter",
        "android": true,
        "ios": false
      },
      {
        "name": "Button",
        "android": true,
        "ios": true
      },
      {
        "name": "Button Group",
        "android": true,
        "ios": true
      },
      {
        "name": "Card",
        "android": true,
        "ios": true
      },
      {
        "name": "Chip",
        "android": true,
        "ios": true
      },
      {
        "name": "Floating Action Button",
        "android": true,
        "ios": true,
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/floating-action-button.md"
      },
      {
        "name": "Forms",
        "children": [
          {
            "name": "Address Form",
            "android": true,
            "ios": true
          },
          {
            "name": "Address Preview",
            "android": true,
            "ios": true
          },
          {
            "name": "Country Selector",
            "android": true,
            "ios": true
          },
          {
            "name": "Amount Input",
            "android": true,
            "ios": true
          },
          {
            "name": "Checkbox",
            "android": true,
            "ios": true
          },
          {
            "name": "Datepicker",
            "android": true,
            "ios": true
          },
          {
            "name": "Form Label",
            "android": true,
            "ios": true
          },
          {
            "name": "Phone Input",
            "android": true,
            "ios": true
          },
          {
            "name": "Password Input",
            "android": true,
            "ios": true
          },
          {
            "name": "Switch",
            "android": true,
            "ios": true
          },
          {
            "name": "Text Area",
            "android": false,
            "ios": true
          },
          {
            "name": "Text Input",
            "android": true,
            "ios": true
          }
        ]
      },
      {
        "name": "Icon",
        "android": true,
        "ios": true,
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/icon.md"
      },
      {
        "name": "Loading indicator",
        "android": true,
        "ios": true
      },
      {
        "name": "Logo View",
        "android": false,
        "ios": true
      },
      {
        "name": "Navigation Controller",
        "android": false,
        "ios": true
      },
      {
        "name": "Page Control",
        "android": false,
        "ios": true
      },
      {
        "name": "Payment Card",
        "android": true,
        "ios": true,
        "designGuidelinesPath": "assets/markdown-files/design-guidelines/payment-card.md"
      },
      {
        "name": "Product Number Display Formatter",
        "android": false,
        "ios": true
      },
      {
        "name": "Progress Bar",
        "android": true,
        "ios": true
      },
      {
        "name": "Separator",
        "android": true,
        "ios": true
      },
      {
        "name": "Slider",
        "android": false,
        "ios": true
      },
      {
        "name": "Snackbar",
        "android": true,
        "ios": false
      },
      {
        "name": "State View",
        "android": true,
        "ios": true
      },
      {
        "name": "Summary Stack",
        "android": true,
        "ios": true
      },
      {
        "name": "Tab Header",
        "android": true,
        "ios": true
      },
      {
        "name": "Toggle Button",
        "android": true,
        "ios": false
      }
    ]
  }
]
