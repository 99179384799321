[
	{
		"parent": "get-started",
		"url": "get-started"
	},
	{
		"parent": "design-tokens",
		"url": "design-tokens"
	},
	{
		"parent": "design-tokens-semantic-colors",
		"url": "design-tokens/semantic-colors"
	},
	{
		"parent": "design-tokens-migration-in-figma",
		"url": "design-tokens/migration-in-figma"
	},
	{
		"parent": "theme",
		"url": "theme"
	},
	{
		"parent": "theme-before-2024-09",
		"url": "theme/before-2024-09"
	},
	{
		"parent": "accessibility",
		"url": "accessibility/overview",
		"defaultChild": "overview",
		"removeFromMenu": false
	},
	{
		"parent": "guides",
		"url": "guides"
	},
	{
		"parent": "guides-web-developers",
		"url": "guides/web-developers/custom-icons",
		"defaultChild": "custom-icons",
		"removeFromMenu": false
	},
	{
		"parent": "web-components",
		"url": "web-components"
	},
	{
		"parent": "web-components-badges",
		"url": "web-components/badges"
	},
	{
		"parent": "web-components-collapsibles",
		"url": "web-components/collapsibles"
	},
	{
		"parent": "web-components-dropdowns",
		"url": "web-components/dropdowns"
	},
	{
		"parent": "web-components-forms",
		"url": "web-components/forms"
	},
	{
		"parent": "web-components-layouts-and-navigation",
		"url": "web-components/layouts-and-navigation/horizontal-layout",
		"defaultChild": "horizontal-layout",
		"removeFromMenu": false
	},
	{
		"parent": "web-components-loaders",
		"url": "web-components/loaders"
	},
	{
		"parent": "web-components-product-items",
		"url": "web-components/product-items"
	},
	{
		"parent": "web-components-states",
		"url": "web-components/states"
	},
	{
		"parent": "web-formatters",
		"url": "web-formatters"
	},
	{
		"parent": "mobile-components",
		"url": "mobile-components"
	},
	{
		"parent": "mobile-components-forms",
		"url": "mobile-components/forms/address-form",
		"defaultChild": "address-form",
		"removeFromMenu": false
	}
]